import { json, redirect } from '@remix-run/node'
import type { LoaderFunctionArgs } from '@remix-run/node'
import { Form, useLoaderData, useSearchParams } from '@remix-run/react'
import { KeyRound } from 'lucide-react'
import { Alert } from '~/components/Alert'

import { Button } from '~/components/Button'
import { Title } from '~/components/Title'
import { authenticator } from '~/modules/auth'
import { getLastLoginEmail } from '~/services/cookies.server'
import { HOMEPAGE_URL } from '~/utils/constants'
import { sentryException } from '~/utils/sentry'

export function meta() {
  return [{ title: 'Log in to your account' }]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request)
  const searchParams = Object.fromEntries(new URL(request.url).searchParams)

  if (user) {
    return redirect(HOMEPAGE_URL)
  }

  const lastLogin = await getLastLoginEmail(request)
  const qs = new URLSearchParams({ ...searchParams, last_login: lastLogin })

  const error = {} as { message: string }
  if (searchParams.error) {
    error.message = searchParams.error_description
    sentryException({ error: searchParams.error_description })
  }

  return json({ error, qs: `?${qs.toString()}` }, { status: searchParams.error ? 400 : 200 })
}

export default function Login() {
  const { error, qs } = useLoaderData<typeof loader>()
  const [searchParams] = useSearchParams()
  const hasInvitation = searchParams.get('invitation')

  return (
    <Form
      action={`/auth0${qs}`}
      className="m-auto flex h-screen w-80 flex-col items-center justify-center gap-4"
      method="post"
    >
      <img alt="Erastus logo" height={100} src="/logo-wra.png" width={100} />
      <Title as="h2" className="text-center" size="h3">
        {hasInvitation ? (
          <>
            You have been invited by <strong>{searchParams.get('organization_name')}</strong>
          </>
        ) : (
          <>Log in to your account</>
        )}
      </Title>
      {error?.message && <Alert type="danger">{error.message}</Alert>}
      <Button leftIcon={<KeyRound />} size="lg" type="submit" variant="default" isFull>
        Continue
      </Button>
    </Form>
  )
}
